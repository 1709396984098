import Swiper, { Autoplay, EffectFade } from 'swiper';

Swiper.use([Autoplay, EffectFade]);

new Swiper('.homeSlider', {
    modules: [Autoplay, EffectFade],
    effect: 'fade',
    loop: true,
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
    }
});